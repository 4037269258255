import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export const useAccessToken = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState('')

  useEffect(() => {
    ;(async () => {
      setAccessToken(await getAccessTokenSilently())
    })()
  }, [getAccessTokenSilently])

  return accessToken
}