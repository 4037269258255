import * as React from 'react';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

function CardPoster({ hubname, hublogo }) {
    return (
        <Card sx=
            {{
                // width: 300, 
                display: { xs: 'none', sm: 'block' },
                // backgroundColor: '#0057b8',
                border: '2px solid #0057b8',
                pb: 1,
                height: '100%',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    p: 1,
                    m: 1,
                    borderRadius: 1,
                    height: '100%',
                }}
            >
                <Typography component="h2" variant="h6" color={'#0057b8'}>
                    C360 Explorer
                </Typography>
                {/* <Avatar alt={hubname} src={hublogo} sx=
                {{ 
                    // width: 220,
                    //  height: 220, 
                     alignSelf: 'center' 
                }} /> */}
                <CardMedia
                    component="img"
                    height="194"
                    image={hublogo}
                    alt={hubname}
                />
                <Typography component="h2" variant="h6" color={'#0057b8'}>
                    {hubname}
                </Typography>
            </Box>
        </Card>
    )
}

export default CardPoster;