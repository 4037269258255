import Nav from '../components/nav';
import { BrowserRouter as Router } from 'react-router-dom';
import DataHubCard from '../components/datahubCard'
import { UserScopes } from '../components/userScopes'
import { useState, useRef } from 'react';
import InsightsHubCard from '../components/insightshubCard';
import DeveloperHubCard from '../components/developerhubCard';
import CustomerHubCard from '../components/customerHubCard';
import { Divider } from '@mui/material';
import AdminUserTable from '../components/adminUserTable';
import Typography from '@mui/material/Typography';
import DecisionHubCard from '../components/decisionHubCard';

function Home() {

  const adminUserTableRef = useRef(null);
  const appsOverviewRef = useRef(null);

  const [enabledHubs, setEnabledHubs] = useState({
    'Data Hub': true,
    'Developer Hub': true,
    'Insigths Hub': true,
    'Customer Hub': true,
    'Decision Hub': true,
  })

  const scopes = UserScopes()
  if (scopes) {
    scopes.data.forEach((hub_data) => {
      if (Object.keys(enabledHubs).includes(hub_data.hub_name)) {
        if (enabledHubs[hub_data.hub_name] === false) {
          setEnabledHubs({ ...enabledHubs, [hub_data.hub_name]: true });
        }
      }
    })
  }

  function handleAdminUserTableClick() {
    adminUserTableRef.current.scrollIntoView({ behavior: 'smooth', block: "end" })
  }
  function handleAppsOverviewClick() {
    appsOverviewRef.current.scrollIntoView({ behavior: 'smooth', block: "end" })
  }

  return (
    <Router>
      <div className="App">
        <Nav handleAdminListClick={handleAdminUserTableClick} handleAppsOverviewClick={handleAppsOverviewClick}>
          <Divider textAlign='left' ref={appsOverviewRef}>
            <Typography variant="h4" gutterBottom component="div" color={'#0057b8'} align='left'>
              Apps
            </Typography>
          </Divider>

          <div>
            {Object.keys(enabledHubs).map((hub) => {
              if (enabledHubs[hub]) {
                return (
                  <>
                    {hub === 'Data Hub' && <DataHubCard />}
                    {hub === 'Insigths Hub' && <InsightsHubCard />}
                    {hub === 'Developer Hub' && <DeveloperHubCard />}
                    {hub === 'Customer Hub' && <CustomerHubCard />}
                    {hub === 'Decision Hub' && <DecisionHubCard />}
                  </>
                )
              } else {
                return <></>
              }
            })}
          </div>

          <Divider textAlign='left' sx={{ mt: 2, mb: 2 }}>
            <Typography variant="h4" gutterBottom component="div" color={'#0057b8'} align='left'>
              Admin List
            </Typography>
          </Divider>

          <AdminUserTable reference={adminUserTableRef} />

        </Nav>
      </div>
    </Router>
  );
}

export default Home;