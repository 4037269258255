import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Divider } from '@mui/material';


export default function AvailabilityCard({ users, markets }) {
    return (
        <Card sx={{
            height: '100%',
        }}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100%"
            >
                <CardContent>
                    <Typography variant="h6" color="text.primary" gutterBottom>
                        {users}
                    </Typography>
                    <Divider sx={{marginBottom:'5px'}}/>
                    <Typography variant="h6" component="div" color="text.secondary">
                        {markets}
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    );
}