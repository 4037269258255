import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardPoster from './cardPoster';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import AvailabilityCard from './availabilityCard';

function InsightsHubCard() {

    return (
        <Grid container spacing={2} mb={4}>
            <Grid item xs={3}>
                <CardPoster hubname={"INSIGHTS HUB"} hublogo={"insightshub.png"} />
            </Grid>
            <Grid item xs={7}>
                <Card sx={{ display: 'flex', height: '100%' }}>
                    {/* <CardPoster hubname={"INSIGHTS HUB"} hublogo={"insightshub.png"} /> */}
                    <CardContent sx={{ flex: 1 }}>
                        <Typography component="h2" variant="h5">
                            Insights Hub
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" align='left'>
                            C360 App providing local business stakeholders (business or IT) with pre-made, standard, centralized dashboards to analyse business performance across a broad range of KPIs accessible to C360 Systems.
                            <br />
                            <br />
                            Connects to C360 Datalake environment. Dashboards Powered by PowerBI.  Documentation powered by Confluence.
                        </Typography>

                        <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />} mt={2}>
                            <div style={{ cursor: 'pointer' }} onClick={() => window.open("https://confluence.grandvision.global/display/CXDData/Customer360", "_blank")}>
                                <Typography variant="subtitle1" color="primary">
                                    Link to confluence documentation
                                </Typography>
                            </div>
                            <Typography variant="subtitle1" color="primary">
                                Link to Insights Hub
                            </Typography>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={2}>
                <AvailabilityCard users={"Business Users"} markets={"No live markets"} />
            </Grid>
        </Grid >
    );
}

export default InsightsHubCard;