import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardPoster from './cardPoster';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import AvailabilityCard from './availabilityCard';
import OpcoSelectDialog from './opcoPopup';

function DataHubCard() {


    // popup related vars
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    // popup related vars

    return (
        <Grid container spacing={2} mb={4}>
            <OpcoSelectDialog open={open}  data={[{'opco':'spain','url':'https://spain.gvc360.io/login/auth0'}]} setOpen={setOpen} />
            <Grid item xs={3}>
                <CardPoster hubname={"DATA HUB"} hublogo={"datahub.png"} />
            </Grid>
            <Grid item xs={7}>
                <Card sx={{ display: 'flex', height: '100%' }}>
                    <CardContent sx={{ flex: 1 }}>
                        <Typography component="h2" variant="h5">
                            Data Hub
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" align='left'>
                            C360 App providing local data stakeholders (business or IT) with the advanced capabilities of the C360 datalake environment via a simplified interface. Connects to C360 Datalake environment (expect all PII to be SHA256 hashed). Includes:
                            <br />
                            <br />
                            -  Query Editor (incl. ability to connect to 3rd party data sources)
                            <br />
                            -  Data Quality Report (pre-set & custom KPI monitoring)
                            <br />
                            -  Direct download
                        </Typography>

                        <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />} mt={2}>
                            <div style={{ cursor: 'pointer' }} onClick={()=> window.open("https://confluence.grandvision.global/display/CXDData/Customer360", "_blank")}>
                                <Typography variant="subtitle1" color="primary">
                                    Link to confluence documentation
                                </Typography>
                            </div>

                            <div onClick={() => handleClickOpen()} style={{ cursor: 'pointer' }}>
                                <Typography variant="subtitle1" color="primary">
                                    Link to Data Hub
                                </Typography>
                            </div>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={2}>
                <AvailabilityCard users={"Data Stakeholders"} markets={"No live markets"} />
            </Grid>
        </Grid >
    );
}

export default DataHubCard;