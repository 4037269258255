import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoginIcon from '@mui/icons-material/Login';
import { useAuth0 } from "@auth0/auth0-react";
import Home from './Home'

const theme = createTheme();

export default function Login() {
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    if (isAuthenticated) {
        return (
            <Home />
        )
    } else {

        return (
            <ThemeProvider theme={theme}>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                        style={{ backgroundColor: '#fff' }}
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            backgroundImage: `url(login.png)`,
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            // backgroundSize: '900px 600px',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100%"
                            flexDirection={'column'}
                        >
                            <Typography
                                component="h1"
                                variant="h3"
                                align="center"
                                color="#0057b8"
                                gutterBottom
                                mb={7}
                            >
                                C360 Explorer
                            </Typography>
                            <Typography variant="h5" align="center" color="text.secondary" paragraph mb={7}>
                                The central hosting environment for all apps build for & powered by the Customer 360 platform.
                            </Typography>
                            <Button variant='contained' onClick={() => loginWithRedirect()} style={{ backgroundColor: "#0057b8" }}>
                                <LoginIcon sx={{ mr: 1 }} />
                                Login with GrandVision
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        );
    }
}