import * as React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Divider } from '@mui/material';


function SimpleDialog(props) {
  const { onClose, selectedValue, open, data } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (item) => {
    onClose(item.opco);
    window.open(item.url, "_blank");
  };

  const style = {
    width: '100%',
    bgcolor: 'background.paper',
    marginBottom: '10px'
  };

  return (
    <Dialog onClose={() => handleClose()} open={open} maxWidth="sm" fullWidth>
      <Divider><DialogTitle align='left'>Select Opco</DialogTitle></Divider>
      <List sx={style} component="nav" aria-label="mailbox folders">
        {data.map((item) => (
          <>
            <ListItem button onClick={() => handleListItemClick(item)} key={item.opco} sx={{ width: '100%' }}>
              <ListItemText primary={item.opco} />
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function OpcoSelectDialog({ open, data,setOpen }) {

  const [selectedValue, setSelectedValue] = React.useState();

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  
  return (
    <div>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={() => handleClose()}
        data={data}
      />
    </div>
  );
}
