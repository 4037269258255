import { useEffect, useState } from 'react'
import { useAccessToken } from './auth'


export const UserScopes = () => {

    async function fetchAuth(token) {
        if (token) {
            const scopes = await fetch('https://adminhub-dev.gvc360.io/api/auth', {
                method: 'GET',
                headers: new Headers({
                    Authorization: `Bearer ${token}`
                })
            })

            return scopes.json();
        }
    }
    const [scopes, setScopes] = useState('')

    const token = useAccessToken()

    useEffect(() => {
        (async () => {
            const scopes = await fetchAuth(token);
            setScopes(scopes)
        })()
    }, [token])
    return scopes


}