import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardPoster from './cardPoster';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import AvailabilityCard from './availabilityCard';


function handleClickOpen() {
    window.open('http://35.204.15.255/', '_blank');
}

function DecisionHubCard() {

    return (
        <Grid container spacing={2} mb={4}>
            <Grid item xs={3}>
                <CardPoster hubname={"DECISION HUB"} hublogo={"decisionhub.png"} />
            </Grid>
            <Grid item xs={7}>
                <Card sx={{ display: 'flex', height: '100%' }}>
                    <CardContent sx={{ flex: 1 }}>
                        <Typography component="h2" variant="h5">
                            Decision Hub
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" align='left'>
                            One stop for deploying and maintaining all business strategies, control logics & predictive analytical based rules.
                            <br />
                            <br />
                            You can design rules, test & deploy it in production and make the rules available for other apps to integrate
                        </Typography>

                        <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />} mt={2}>
                            <div style={{ cursor: 'pointer' }} onClick={() => window.open("https://confluence.grandvision.global/display/CXDData/Customer360", "_blank")}>
                                <Typography variant="subtitle1" color="primary">
                                    Link to confluence documentation
                                </Typography>
                            </div>

                            <div onClick={() => handleClickOpen()} style={{ cursor: 'pointer' }}>
                                <Typography variant="subtitle1" color="primary">
                                    Link to Decision Hub Sample tenant
                                </Typography>
                            </div>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={2}>
                <AvailabilityCard users={"Data Stakeholders"} markets={"No live markets"} />
            </Grid>
        </Grid >
    );
}

export default DecisionHubCard;